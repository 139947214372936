<template>
  <div id="inspectorId">
    <div class="page-container pb-0 mb-2">
      <div id="filter-custom">
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>
          <b-col
            md="3"
            xl="3"
            class="mb-2"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Trạng thái <span class="required"></span></label>
              <v-select
                :reduce="item => item.en"
                label="vi"
                :options="status"
                :placeholder="'Lựa chọn trạng thái'"
                @input="filterStatus"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            <!-- @input="fetchList" -->
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm tài liệu'"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="() => {}"
        @clickExportExcel="() => {}"
        @importFile="() => {}"
        @clickAdd="showModalEdit"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        :columns="columns"
        :rows="dataList || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.fileName == 'fileName'">
            {{ props.row.fileName }}
          </span>
          <span v-else-if="props.column.field == 'digitalSignatureStatusString'">
            <div v-if="props.row.digitalSignatureStatus == 'NonDigitalSignature'">
              <b-badge variant="light-danger">{{ props.row.digitalSignatureStatusString }}</b-badge>
            </div>
            <div v-else>
              <b-badge variant="light-success">{{ props.row.digitalSignatureStatusString }}</b-badge>
            </div>
          </span>
          <!-- Chức năng -->
          <span v-else-if="props.column.field === 'Action'">

            <span
              v-if="props.row.digitalSignatureStatus == 'DigitalSignature'"
              @click="showModalInfo(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xem chí tiết ký số'"
                icon="EyeIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              v-if="props.row.digitalSignatureStatus !== 'DigitalSignature'"
              @click="digitalSigned(props.row)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="18"
                class="text-body"
              />
            </span>
            <a
              class="ml-2"
              :href="$serverfile + props.row.filePath"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Tải xuống'"
                icon="DownloadIcon"
                size="18"
                class="text-body"
              />
            </a>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />
      <create-doc
        :id="EditManagerDoc"
        :title="'Thêm tài liệu ký số'"
        @accept="handleModal"
      />
      <info
        :id="InfoId"
        :dataDetail="dataDetail"
        :title="'Xem tài liệu ký số'"
      />
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import CreateDoc from './components/CreateDoc.vue'
import Info from './components/Info.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import signDigital from '../signDigital'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    CreateDoc,
    Info,
    VSelect,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      signDigital,
      status: [
        {
          vi: 'Đã ký số',
          en: 'DigitalSignature',
        },
        {
          vi: 'Chưa ký số',
          en: 'NonDigitalSignature',
        },

      ],
      totalRecord: 10,
      columns: [
        {
          label: 'TÊN TÀI LIỆU',
          field: 'fileName',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'digitalSignatureStatusString',
          sortable: false,
          width: '700px',

        },
        {
          label: 'CHỨC NĂNG',
          field: 'Action',
          sortable: false,
          width: '150px',
        },
      ],
      dataList: [],
      currentPage: 0,
      urlQuery: {
        status: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataDetail: {},
      idDetail: '',
      InfoId: 'InfoId',
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateInspector: 'comfirm-create',
      deleteIds: [],
      showBtnMultiDelete: false,
      EditManagerDoc: 'EditManagerDoc',
    }
  },
  mounted() {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      './js/vgcaplugin.js',
    )
    plugin.async = true
    document.head.appendChild(plugin)
  },
  created() {
    this.fetchData(this.urlQuery)
    window.checkPluginCallback = this.checkVNPT_CAPlugin_Callback
    window.signCallback = this.signCallback
    window.setLicenseCallback = this.setLicenseCallback
  },
  methods: {
    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalEdit() {
      this.$bvModal.show(this.EditManagerDoc)
    },
    async showModalInfo(id) {
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_DIGITAL}${this.idDetail}`)
      this.dataDetail = data
      this.$bvModal.show(this.InfoId)
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    // Phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_DIGITAL, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // Thực hiện delete
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_DIGITAL, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    async handleModal(param) {
      const formData = new FormData()
      formData.append('IsSecure', param.modelFormData.isSecure)
      formData.append('formFile', param.modelFormData.files)
      formData.append('FileName', param.fileName)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_DIGITAL, formData).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.EditManagerDoc)
          this.fetchData(this.urlQuery)
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // lấy danh sách khi tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
    // Bộ lọc
    async filterStatus(val) {
      if (val != null) {
        this.urlQuery.status = val
        this.urlQuery.pageNumber = 1
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = ''
        this.fetchData(this.urlQuery)
      }
    },
    digitalSigned(val) {
      val.fileUrl = this.$serverfile + val.filePath
      val.fileUploadHandler = `${this.$serverfile}home/upload-file-v2/${val.id}`
      this.signDigital.signPdf(val, this.signCallback)
    },
    signCallback(val) {
      val = JSON.parse(val)
      if (val.Status > 0) {
        this.$bvToast.toast(val.Message, {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        this.$bvToast.toast('Ký số thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
