export default {
  signPdf: (val, callback) => {
    const prms = {}
    prms.FileUploadHandler = val.fileUploadHandler
    prms.SessionId = ''
    prms.FileName = val.fileUrl
    prms.MetaData = [val]
    // eslint-disable-next-line no-undef
    vgca_sign_file(JSON.stringify(prms), callback)
  },
}
