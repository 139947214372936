<template>
  <b-modal
    :id="id"
    size="md"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="accept($event)"
    @hidden="resetData()"
  >
    <validation-observer ref="rule">
      <b-form-group>
        <label>Tên tài liệu</label>
        <div class="d-flex">
          <b-form-input
            v-model="dataInput.fileName"
            :disabled="true"
          >
            {{ dataInput.fileName }}
          </b-form-input>
          <input
            id="file"
            ref="upload-file"
            type="file"
            class="d-none"
            accept=".pdf"
            @change="importFileExcel"
          />
          <b-button
            variant="outline-primary"
            class="text-nowrap ml-1"
            @click="uploadFile"
          >
            <div
              class="d-flex"
            >
              <feather-icon
                icon="UploadIcon"
                for="file"
                class="input_label text-primary"
              />
              <label
                class="text-primary ml-50"
              >Tải lên</label>
            </div>
          </b-button>
        </div>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
    BFormTextarea,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },

  },

  data() {
    return {
      nameFile: null,
      dataInput: {
        fileName: '',
        modelFormData: {},
      },
    }
  },
  methods: {
    resetData() {
      this.dataInput = {
        fileName: '',
        modelFormData: {},
      }
    },
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
  },
}
</script>
